import { graphql, Link } from "gatsby"
import get from "lodash/get"
import * as React from "react"
import Layout from "../layouts/RootLayout"

class Blog extends React.Component {
  render() {
    const articles = get(this, "props.data.allBuilderModels.article")

    return (
      <Layout>
        <div className="bg-secondary">
          <div className="bg-dark pt-4 pb-5">
            <div className="container pt-2 pb-3 pt-lg-3 pb-lg-4">
              <div className="d-lg-flex justify-content-between pb-3">
                <div className="order-lg-2 mb-3 mb-lg-0 pt-lg-2">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb breadcrumb-light flex-lg-nowrap justify-content-center justify-content-lg-start">
                      <li className="breadcrumb-item">
                        <a className="text-nowrap" href="/">
                          <i className="czi-home"></i>Accueil
                        </a>
                      </li>
                      <li className="breadcrumb-item text-nowrap">
                        <a href="/blog">Blog</a>
                      </li>
                      <li
                        className="breadcrumb-item text-nowrap active"
                        aria-current="page"
                      >
                        Tous les articles
                      </li>
                    </ol>
                  </nav>
                </div>
                <div className="order-lg-1 pr-lg-4 text-center text-lg-left">
                  <h1 className="h3 text-light mb-0">Nos conseils</h1>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-5">
            <div className="row justify-content-center pt-5 mt-2">
              {articles.map(article => (
                <section className="col-lg-9">
                  <article className="border-bottom pb-4 mb-5 ml-2">
                    <h2 className="h5 blog-entry-title">
                      <Link to={"/blog/" + article.content.data.handle}>
                        {article.content.data.title}
                      </Link>
                    </h2>
                    <p className="fs-sm">
                      {article.content.data.description} <br />
                      <Link
                        to={"/blog/" + article.content.data.handle}
                        className="blog-entry-meta-link fw-medium"
                      >
                        [Lire la suite]
                      </Link>
                    </p>
                  </article>
                </section>
              ))}
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}
export default Blog

export const pageQuery = graphql`
  query BlogQuery {
    site {
      siteMetadata {
        title
      }
    }
    allBuilderModels {
      article(options: { cachebust: true }) {
        content
      }
    }
  }
`
